import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ApplicationError, EErrorCodeClient, EErrorKind } from '@money.energy/common-errors';
import { setIsRevokeThrowingError, setIsTimeoutErrorMessage } from './cache';
import typePolicies from './typePolices';

const REST_URL = process.env['REACT_APP_URL'] as string;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
  if (graphQLErrors) {
    setIsRevokeThrowingError(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const err of graphQLErrors) {
      const { extensions } = err;
      setIsRevokeThrowingError(true);
      const e = ApplicationError.getShapeByAppCode(extensions?.['applicationCode'] as number);
      if (e.kind === EErrorKind.CLIENT) {
        if (e.code === EErrorCodeClient.INSUFFICIENT_FUNDS) {
          return;
        }
      }
    }
  } else if (networkError) {
    setIsRevokeThrowingError(true);
  } else {
    setIsRevokeThrowingError(true);
  }
});

const connectionParams = () => {
  return {};
};

const authLink = setContext((_) => {
  return {
    headers: {
      ...connectionParams(),
    },
  };
});

const httpLink = new HttpLink({
  uri: REST_URL,
});

const cache = new InMemoryCache({
  typePolicies,
});

const client = new ApolloClient({
  link: authLink.concat(from([errorLink, httpLink])),
  cache,
});

export default client;
