import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from '../components/bootstrap';
import CatalogCarousel from '../components/CatalogCarousel/CatalogCarousel';

const Home: React.FC = () => {
  return (
    <Container>
      <CatalogCarousel />
      <Row className="mt-3">
        <Col className="d-grid">
          <Link className="btn btn-outline-primary" to="/catalog">
            All Games
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
