import { gql } from '@apollo/client';

export const getSlotsCatalogGql = gql`
  {
    slotCatalog {
      id
      name
      slug
      licenses
      features
      codeName
    }
  }
`;

export const getSlotGql = gql`
  query GetSlot($input: String!) {
    slot(slotId: $input) {
      slot {
        id
        name
        slug
        licenses
        features
        codeName
      }
    }
  }
`;
