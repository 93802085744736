import { Icon } from '@mdi/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { mdiPlayCircleOutline } from '@mdi/js';
import { ISlotData } from '../../global';
import { Image } from '../bootstrap';

const CatalogItem: React.FC<{ slot: ISlotData }> = ({ slot }) => (
  <div className="d-flex flex-column mx-3" key={slot.id}>
    <Image rounded fluid src={`/images/${slot.slug}.jpg`} />
    <h3 className="text-center">{slot.name}</h3>
    <div className="d-grid mt-auto">
      <Link className="btn btn-outline-light" to={`/game/${slot.id}`}>
        <Icon className="me-2" path={mdiPlayCircleOutline} size={1} />
        Demo
      </Link>
    </div>
  </div>
);

export default CatalogItem;
