import React, { useEffect, useMemo } from 'react';
import { Container, Spinner } from '../components/bootstrap';
import CatalogItem from '../components/CatalogItem/CatalogItem';
import { ISlotData } from '../global';
import { getSlotsCatalog } from '../services/slot';

const CarouselLoader: React.FC = () => (
  <div className="d-flex align-items-center justify-content-center" style={{ height: '415px' }}>
    <Spinner className="m-4" animation="grow" />
  </div>
);

const Catalog: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [slots, setSlots] = React.useState<ISlotData[]>([]);

  useEffect(() => {
    getSlotsCatalogFn();
  }, []);

  const getSlotsCatalogFn = async () => {
    try {
      setIsLoading(true);

      const data = await getSlotsCatalog();

      setSlots(data);
    } catch (error) {
      console.error('getSlotsCatalogFn', error);
    } finally {
      setIsLoading(false);
    }
  };

  const items = useMemo(() => slots.map((slot) => <CatalogItem key={slot.id} slot={slot} />), [slots]);

  return <Container>{isLoading ? <CarouselLoader /> : <div className="catalog">{items}</div>}</Container>;
};

export default Catalog;
