import { ISlotData } from '../global';
import client from '../gql/client';
import { getSlotGql, getSlotsCatalogGql } from '../gql/query';

export const getSlot = async (id: string) => {
  const { data } = await client.query<{ slot: { slot: ISlotData } }>({
    query: getSlotGql,
    variables: { input: id },
    fetchPolicy: 'network-only',
  });

  return data.slot?.slot;
};

export const getSlotsCatalog = async () => {
  const { data } = await client.query<{ slotCatalog: ISlotData[] }>({
    query: getSlotsCatalogGql,
    variables: { input: {} },
    fetchPolicy: 'network-only',
  });

  return data.slotCatalog;
};
