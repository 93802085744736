import { isMobileDevice } from '../utils';
import { setIsAuthorized, setProgress } from './cache';

const typePolicies = {
  Query: {
    fields: {
      isAuthorized: {
        read(): boolean {
          return setIsAuthorized();
        },
      },
      progress: {
        read(): { status: number; wasLoaded?: boolean } {
          return setProgress();
        },
      },
      isMobile: {
        read(): boolean {
          return isMobileDevice();
        },
      },
    },
  },
};

export default typePolicies;
