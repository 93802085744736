declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key as string]: value,
    };
  }, {});
  return param as T;
};

export const wrap =
  (fn: CallableFunction, ...partOne: Helper.RestArguments) =>
  (...partTwo: Helper.RestArguments): unknown => {
    const args: Helper.RestArguments = [...partOne, ...partTwo];
    if (args.length) {
      return fn(...args);
    }
    return fn();
  };

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      typeof (window.navigator as unknown as { standalone: unknown }).standalone !== 'undefined')
  );
};

export const isTabletPortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return !isLandscape && _deviceWidth >= 768 && _deviceWidth <= 1366;
};
export const isTabletLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isLandscape && _deviceWidth >= 950 && _deviceHeight < 1200 && _deviceWidth < 1400;
};
export const isMobilePortrait = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return !isLandscape && _deviceWidth < 768;
};
export const isMobileLandscape = (_deviceWidth: number, _deviceHeight: number): boolean => {
  const isLandscape = _deviceWidth >= _deviceHeight;
  return isLandscape && _deviceWidth < 950;
};
