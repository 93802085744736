import { makeVar } from '@apollo/client';

export const setIsAuthorized = makeVar<boolean>(false);

export const setProgress = makeVar<{ status: number; wasLoaded?: boolean }>({
  status: 0,
  wasLoaded: false,
});

export const setBrokenGame = makeVar<boolean>(false);

export const setIsRevokeThrowingError = makeVar<boolean>(false);

export const setIsTimeoutErrorMessage = makeVar<boolean>(false);
